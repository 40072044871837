<template>
    <div v-if="variants.length >= 4" class="flex flex-col items-center justify-center py-10 sm:py-20 px-5 overflow-x-hidden reference-linear-gradient">
        <div class="flex flex-col sm:flex-row items-center justify-between w-full md:w-156 lg:w-239 2xl:w-322 mb-2.5 pb-4">
            <div class="flex flex-col mb-5">
                <h2 class="text-2xl font-bold text-center sm:text-left text-heading">
                    {{ $t(`text-latest-${contentType}`) }}
                </h2>
                <div class="text-base font-normal text-center sm:text-left text-sub-heading">
                    {{ $t(`text-latest-${contentType}-subtitle`) }}
                </div>
            </div>
            <div v-if="variants.length > 4" class="flex items-center justify-end space-x-5 flex-shrink-0 ml-5">
                <div class="flex items-center justify-end space-x-2">
                    <MenuButton
                        variant="basePanel"
                        :disabled="page === 0"
                        :aria-label="$t('text-previous')"
                        :on-click="handlePrev"
                    >
                        <ChevronLeft class="w-3 h-3" />
                    </MenuButton>
                    <MenuButton
                        variant="basePanel"
                        :disabled="Math.floor(pagination.itemCount / gridSize.width.value / 2) === page"
                        :aria-label="$t('text-next')"
                        :on-click="handleNext"
                    >
                        <ChevronRight class="w-3 h-3" />
                    </MenuButton>
                </div>
            </div>
        </div>
        <div class="w-full flex justify-center">
          <div v-if="gridPage.length" class="w-full sm:w-fit grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-9 gap-y-2.5 sm:gap-y-9">
            <RenderReferenceCard
                v-for="item in gridPage"
                :key="item?.variant?.id"
                :show="item.show"
                :variant="item.variant"
                :class-name="item.hidden ? 'hidden' : ''"
                loading="lazy"
            />
          </div>
        </div>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import { useContentStore } from '@stores/content';
import MenuButton from "@components/ui/menu-button";
import ChevronLeft from "@components/icons/chevron-left";
import ChevronRight from "@components/icons/chevron-right";
import RenderReferenceCard from "@components/reference/render-reference-card";

const props = defineProps({
  prefix: {
    type: String,
    required: true
  },
  subtype: {
    type: String,
    required: true
  },
  baseRouteCode: {
    type: String,
    required: true
  },
  contentType: {
    type: String,
    required: true
  }
});


const config = useRuntimeConfig();
const auth = useAuthStore();
const store = useSelectedStore();
const category = useState('route.category', () => config.public.layoutDesign);
const contents = useContentStore();
const loading = ref(false);
const isMounted = ref(false);
const page = ref(0);
const pages = useState(`contents.${props.subtype}.pages.${category.value}`, () => []);
const pagination = useState(`contents.${props.subtype}.pagination.${category.value}`, () => ({ page: 1, pageCount: 1, itemsPerPage: 24, itemCount: 0 }));
const gridSize = useGridSize();
const variants = useState(`contents.${props.subtype}.variants.${category.value}`, () => []);
const gridPage = ref([]);
const fader = useFader(variants, page, gridSize, gridPage);
const routeLang = useRouteLang();

const reload = async () => {
  if (loading.value) {
    return;
  }
  if (page.value === 0) loading.value = true;

  try {
    const data = pagination.value.page === 1 && !_.isEmpty(_.get(contents, 'reference.items'))
      ? contents.reference
      : await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.CONTENT_POST}`, {
        method: 'POST',
        params: {
          type: props.contentType,
          page: pagination.value.page,
          limit: pagination.value.itemsPerPage,
          locale: routeLang.locale.value
        }
      });

    pages.value.push(data);
    pagination.value = data.pagination;
    fader.addVariants(_.get(data, 'items', []));
    fader.scheduleShow(page.value, page.value);
  } catch (error) {
    if (import.meta.client && !auth.reloadTimeout) {
      // $toast.error(t(`error.${props.prefix}-detail.load`));
    }
    Sentry.captureException(error);
  }
  if (page.value === 0) loading.value = false;
}

watch(() => store.loadReset, async values => {
  if (values[props.subtype] && (import.meta.server || navigator?.onLine)) {
    fader.setVariants([]);
    pages.value = [];
    pagination.value.page = 1;
    await reload();
    fader.resetShow(gridSize.width.value);
    store.setLoadReset(props.subtype, false);
  }
}, { deep: true });

onServerPrefetch(async () => {
  pagination.value.page = 1;
  await reload();
});

onMounted(async () => {
  isMounted.value = true;
  if ((_.isEmpty(pages.value) || store.loadReset[props.subtype] || auth.isLoggedIn) && (import.meta.server || navigator?.onLine)) {
    store.setLoadReset(props.subtype, false);
    fader.setVariants([]);
    pages.value = [];
    pagination.value.page = 1;
    await reload();
  }
  fader.resetShow(gridSize.width.value);
});

const handlePrev = () => {
    page.value = Math.max(0, page.value - 1);
}

const handleNext = async () => {
    page.value = Math.min(Math.floor(pagination.value.itemCount / gridSize.width.value / 2), page.value + 1);
    if ((page.value + 1) * gridSize.width.value * 2 >= variants.value.length) {
        pagination.value.page++;
        await reload();
    }
}

</script>
